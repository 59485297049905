<template>
    <div v-loading="loading" class="contractArchives">
        <header>
            <div class="item item-border">
                <div>
                    <span class="circle">
                        <span class="iconfont iconhetong2"></span>
                    </span>
                </div>
                <div>
                    <div class="item_title">
                        合同编号：
                    </div>
                    <div>
                        <span @click="openProcessManagement" class="contractNo">{{ contractInfo.contract_number }}</span>
                        <el-button @click="openHistoricalContract" v-if="old_contract_number != ''" type="primary">
                            查看历史信息
                            <i class="el-icon-d-arrow-right"></i>
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="item item-border">
                <div>
                    <span class="circle">
                        <span class="iconfont iconqiye"></span>
                    </span>
                </div>
                <div>
                    <div class="item_title">
                        客户名称：
                    </div>
                    <div>
                        <el-tooltip
                            class=""
                            effect="dark"
                            :content="contractInfo.client_name"
                            placement="bottom"
                        >
                            <span class="tip_span">{{ contractInfo.client_name }}</span>
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div class="item">
                <div>
                    <span class="circle">
                        <span class="iconfont icongongdi"></span>
                    </span>
                </div>
                <div>
                    <div class="item_title">
                        工程名称：
                    </div>
                    <div>
                        <el-tooltip
                            class=""
                            effect="dark"
                            :content="contractInfo.engineering_name"
                            placement="bottom"
                        >
                            <span class="tip_span">{{ contractInfo.engineering_name }}</span>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </header>
        <main class="main_box">
            <div class="payment payment_t" v-if="versionMark == 2">
                <div class="payment_title payment_title_t">
                    付款方式
                </div>
                <div class="payment_txt payment_txt_t">
                    <el-tooltip
                        class=""
                        effect="dark"
                        :content="contractInfo.settlement_mode_and_payment_clause"
                        placement="bottom"
                    >
                        <span class="tip_span">{{ contractInfo.settlement_mode_and_payment_clause }}</span>
                    </el-tooltip>
                </div>
            </div>
            <div class="pricing_box" v-if="versionMark == 2">
                <div class="pricing pricing1">
                    <div class="pricing_l b_color_lan">
                        企业固定价
                    </div>
                    <div>
                        <div
                            class="title_box"
                            v-for="(item, i) in gradeData"
                            :key="i"
                        >
                            <span class="title_span b_color_lan">{{ item.title }}</span>
                            <span
                                class="title_sp"
                                v-for="(dat, ii) in item.data"
                                :key="ii"
                            >
                                <span v-if="dat.product_mark_num">
                                    {{ dat.product_mark_num }}：
                                    <span class="c_color_lan">{{ dat.product_mark_price }}</span>
                                    ；
                                </span>
                            </span>
                        </div>
                        <div
                            class="title_box"
                        >
                            <span class="title_span b_color_lan">
                                其他
                            </span>
                            <span
                                class="title_sp"
                            >
                                地泵：
                                <span class="c_color_lan" v-if="contractInfo.contract_price_versions">
                                    {{ contractInfo.contract_price_versions.ground_pump_price }}
                                </span>
                                元/方；
                            </span>
                            <span
                                class="title_sp"
                            >
                                汽车泵费：
                                <span class="c_color_lan" v-if="contractInfo.contract_price_versions">
                                    {{ contractInfo.contract_price_versions.pump_price }}
                                </span>
                                元/方；
                            </span>
                        </div>
                        <div class="title_box">
                            <el-button
                                size="mini"
                                class="title_span_btn"
                                @click="openPopFun(1)"
                                type="primary"
                            >
                                历史调价记录
                                <i class="el-icon-d-arrow-right"></i>
                            </el-button>
                        </div>
                    </div>
                </div>
                <div class="pricing">
                    <div class="pricing_l b_color_qing">
                        市场信息价
                    </div>
                    <div>
                        <div
                            class="title_box"
                            v-for="(item, i) in additionData"
                            :key="i"
                        >
                            <span class="title_span b_color_lan">{{ item.title }}</span>
                            <span
                                class="title_sp"
                                v-for="(dat, ii) in item.data"
                                :key="ii"
                            >
                                <span v-if="dat.product_mark_num">
                                    {{ dat.product_mark_num }}：
                                    <span class="c_color_lan">{{ dat.product_mark_price }}</span>
                                    ；
                                </span>
                            </span>
                        </div>
                        <div
                            class="title_box"
                        >
                            <span class="title_span b_color_lan">
                                其他
                            </span>
                            <span
                                class="title_sp"
                            >
                                地泵：
                                <span class="c_color_lan" v-if="contractInfo.contract_price_versions">
                                    {{ contractInfo.contract_price_versions.ground_pump_price }}
                                </span>
                                元/方；
                            </span>
                            <span
                                class="title_sp"
                            >
                                汽车泵费：
                                <span class="c_color_lan" v-if="contractInfo.contract_price_versions">
                                    {{ contractInfo.contract_price_versions.pump_price }}
                                </span>
                                元/方；
                            </span>
                        </div>
                        <div class="title_box">
                            <el-button
                                size="mini"
                                class="title_span_btn b_color_qing"
                                @click="openPopFun(2)"
                                type="primary"
                            >
                                历史调价记录
                                <i class="el-icon-d-arrow-right"></i>
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="payment payment_bbai" v-if="versionMark == 2">
                <div class="payment_title payment_title_bc">
                    已付款：{{ proceedsDetail.total_payment }}
                </div>
                <div class="payment_txt">
                    <div
                        v-for="(item, i) in proceedsDetail.payment_list"
                        :key="i"
                        :class="`${i % 2 !== 0 ? 'hexagon_bred' : ''} hexagon_box`"
                    >
                        <div v-if="(i % 2) !== 0" class="hexagon_bno"></div>
                        <div>
                            <div class="c_color_cheng">
                                {{ item.payment }}
                            </div>
                            <div>
                                {{ item.receipt_date }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="payment payment_bbai" v-if="versionMark == 2">
                <div class="payment_title payment_title_bl">
                    退款记录：{{ refundDetail.total_payment }}
                </div>
                <div class="payment_txt">
                    <div
                        v-for="(item, i) in refundDetail.payment_list"
                        :key="i"
                        :class="`${i % 2 !== 0 ? 'hexagon_blan' : ''} hexagon_box`"
                    >
                        <div v-if="(i % 2) !== 0" class="hexagon_bno"></div>
                        <div>
                            <div class="c_color_s_lan">
                                {{ item.payment }}
                            </div>
                            <div>
                                {{ item.receipt_date }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="payment_goods">
                <div v-if="versionMark == 1" class="payment_title_bzb">
                    发货情况（方）
                </div>
                <div class="payment_top">
                    <div class="payment_title" v-if="versionMark == 2">
                        发货情况（方）
                    </div>
                    <div class="payment_inner">
                        <div class="payment_inner_chunk payment_inner_b">
                            <span>
                                合同启用转换时间：
                            </span>
                            {{ contractInfo.enable_time }}
                        </div>
                        <div class="payment_inner_chunk">
                            <span>
                                首次供应时间：
                            </span>
                            {{ statisticsDetail.first_goods_time }}
                        </div>
                        <div class="payment_inner_chunk payment_inner_b">
                            <span>
                                最后一次供应时间：
                            </span>
                            {{ statisticsDetail.latest_supply_time }}
                        </div>
                        <div class="payment_inner_chunk">
                            <span>
                                最后一次付款时间：
                            </span>
                            {{ statisticsDetail.latest_payment_time }}
                        </div>
                        <div class="payment_inner_chunk payment_inner_b">
                            <span>
                                最后一个订单时间：
                            </span>
                            {{ scheduleDetail.latest_order_time }}
                        </div>
                    </div>
                </div>
                <div class="payment_bottom">
                    <div class="payment_bottom_chunk">
                        销售方量：
                        <span>
                            {{ statisticsDetail.xsfl }}
                        </span>
                    </div>
                    <div class="payment_bottom_chunk">
                        结算方量：
                        <span>
                            {{ statisticsDetail.jsfl }}
                        </span>
                    </div>
                    <div class="payment_bottom_chunk">
                        发货方量：
                        <span>
                            {{ statisticsDetail.fhfl }}
                        </span>
                    </div>
                    <div class="payment_bottom_chunk">
                        生产方量：
                        <span>
                            {{ statisticsDetail.scfl }}
                        </span>
                    </div>
                    <div class="payment_bottom_chunk">
                        差方：
                        <span>
                            {{ statisticsDetail.ttfl }}
                        </span>
                    </div>
                    <div class="payment_bottom_chunk">
                        订单方量：
                        <span>
                            {{ scheduleDetail.order_total_quantity }}
                        </span>
                    </div>
                    <div class="payment_bottom_chunk">
                        剩余方量：
                        <span>
                            {{ scheduleDetail.surplus_capacity }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="money_box" v-if="versionMark == 2">
                <div class="money_top">
                    <div class="money_title money_title_bq">
                        合同账务明细（元）
                    </div>
                </div>
                <div class="money_center">
                    <div class="money_center_chunk">
                        <div>
                            累计应收：
                        </div>
                        <div class="c_color_lan">
                            {{ ljysVal }}
                        </div>
                    </div>
                    <div class="money_center_chunk">
                        <div>
                            累计已核应收：
                        </div>
                        <div class="c_color_lan">
                            {{ scheduleDetail.check_computation_receivable }}
                        </div>
                    </div>
                    <div class="money_center_chunk">
                        <div>
                            累计按节点应收：
                        </div>
                        <div class="c_color_lan">
                            {{ scheduleDetail.aggregate_receivables }}
                        </div>
                    </div>
                    <div class="money_center_chunk">
                        <div>
                            累计实收：
                        </div>
                        <div class="c_color_lan">
                            {{ ljssVal }}
                        </div>
                    </div>
                    <div class="money_center_chunk">
                        <div>
                            逾期欠款：
                        </div>
                        <div class="c_color_lan">
                            {{ yqqkVal }}
                        </div>
                    </div>
                    <div class="money_center_chunk">
                        <div>
                            累计回款率：
                        </div>
                        <div class="c_color_lan">
                            {{ scheduleDetail.cumulative_return_rate }}
                        </div>
                    </div>
                    <div class="money_center_chunk">
                        <div>
                            授信金额占比：
                        </div>
                        <div class="c_color_lan">
                            {{ scheduleDetail.percent }}
                        </div>
                    </div>
                    <div class="money_center_chunk b_color_cheng">
                        <div>
                            授信金额：
                        </div>
                        <div class="c_color_cheng">
                            {{ scheduleDetail.credit_money }}
                        </div>
                    </div>
                    <div class="money_center_chunk b_color_cheng">
                        <div>
                            耗用授信金额：
                        </div>
                        <div class="c_color_cheng">
                            {{ scheduleDetail.credit_consume_money }}
                        </div>
                    </div>
                    <div class="money_center_chunk b_color_cheng">
                        <div>
                            剩余授信金额：
                        </div>
                        <div class="c_color_cheng">
                            {{ scheduleDetail.credit_surplus_money }}
                        </div>
                    </div>
                    <div class="money_center_chunk b_color_s_lan">
                        <div>
                            供应状态：
                        </div>
                        <div class="c_color_s_lan">
                            {{ scheduleDetail.contract_status_name }}
                        </div>
                    </div>
                </div>
                <div class="money_center">
                    <div class="money_center_chunk b_color_red">
                        <div>
                            超限申请额度：
                        </div>
                        <div class="c_color_red">
                            {{ scheduleDetail.transfinite_apply_total_money }}
                        </div>
                    </div>
                    <div class="money_center_chunk b_color_red">
                        <div>
                            已用超限额度：
                        </div>
                        <div class="c_color_red">
                            {{ scheduleDetail.transfinite_use_total_money }}
                        </div>
                    </div>
                    <div class="right_btn">
                        <el-button
                            size="mini"
                            class="title_span_btn"
                            @click="openPopSgjdFun()"
                            type="primary"
                        >
                            施工进度
                            <i class="el-icon-d-arrow-right"></i>
                        </el-button>
                        <el-button
                            size="mini"
                            class="title_span_btn"
                            @click="openPopTcFun()"
                            type="primary"
                        >
                            调差
                            <i class="el-icon-d-arrow-right"></i>
                        </el-button>
                        <el-button
                            size="mini"
                            class="title_span_btn"
                            @click="openPopGksjFun('gksj')"
                            type="primary"
                        >
                            管控数据
                            <i class="el-icon-d-arrow-right"></i>
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="table_box" :class="{table_box_bzb: versionMark == 1}">
                <div class="date-picker">
                    时间段
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        @change="selectList"
                        value-format="yyyy-MM-dd"
                        size="small"
                    >
                    </el-date-picker>
                    <el-button
                        size="mini"
                        class="export_btn"
                        @click="exportData"
                        type="primary"
                    >
                        导出
                    </el-button>
                </div>
                <div class="table-bg" :class="{table_bg_bzb: versionMark == 1}">
                    <el-table
                        :data="tableData"
                        style="width: 100%"
                        stripe
                    >
                        <el-table-column
                            type="index"
                            label="序号"
                            width="80"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="creation_date"
                            label="日期"
                            width="120"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="task_number"
                            label="任务单号"
                            width="180"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="station_name"
                            label="供应场站"
                        >
                        </el-table-column>
                        <el-table-column
                            label="施工部位"
                        >
                            <template slot-scope="scope">
                                <span :title="scope.row.place_detail">{{ scope.row.place_detail }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="砼品种"
                        >
                            <template slot-scope="scope">
                                <span :title="scope.row.concrete_varieties">{{ scope.row.concrete_varieties }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="adjusted_sign_volume"
                        >
                            <template slot="header">
                                <span class="green">签收方量</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="green">{{ scope.row.adjusted_sign_volume }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="adjusted_sign_volume"
                        >
                            <template slot="header">
                                <span class="green">砼方量</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="green">{{ scope.row.adjusted_concrete_volume }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="adjusted_concrete_price"
                            v-if="versionMark == 2"
                        >
                            <template slot="header">
                                <span class="green">砼单价</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="green">{{ scope.row.adjusted_concrete_price }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="adjusted_concrete_amount_money"
                            v-if="versionMark == 2"
                        >
                            <template slot="header">
                                <span class="green">砼金额</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="green">{{ scope.row.adjusted_concrete_amount_money }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="adjusted_pump_volume"
                        >
                            <template slot="header">
                                <span class="blue">砂浆方量</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="blue">{{ scope.row.adjusted_mortar_volume }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="adjusted_pump_volume"
                            v-if="versionMark == 2"
                        >
                            <template slot="header">
                                <span class="blue">砂浆单价</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="blue">{{ scope.row.adjusted_mortar_price }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="adjusted_pump_volume"
                            v-if="versionMark == 2"
                        >
                            <template slot="header">
                                <span class="blue">砂浆金额</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="blue">{{ scope.row.adjusted_mortar_amount_money }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="adjusted_pump_volume"
                        >
                            <template slot="header">
                                <span class="blue">泵送方量</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="blue">{{ scope.row.adjusted_pump_volume }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="adjusted_pump_money"
                            v-if="versionMark == 2"
                        >
                            <template slot="header">
                                <span class="blue">泵送金额</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="blue">{{ scope.row.adjusted_pump_money }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="adjusted_freight"
                            v-if="versionMark == 2"
                        >
                            <template slot="header">
                                <span class="purple">运费</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="purple">{{ scope.row.adjusted_freight }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="replenish_quantity"
                        >
                            <template slot="header">
                                <span class="purple">补方数</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="purple">{{ scope.row.replenish_quantity }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="adjusted_replenish_quantity"
                            width="120"
                        >
                            <template slot="header">
                                <span class="purple">调整后补方数</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="purple">{{ scope.row.adjusted_replenish_quantity }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="replenish_money"
                            v-if="versionMark == 2"
                        >
                            <template slot="header">
                                <span class="purple">补方金额</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="purple">{{ scope.row.replenish_money }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="adjusted_replenish_money"
                            v-if="versionMark == 2"
                            width="120"
                        >
                            <template slot="header">
                                <span class="purple">调整后补方金额</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="purple">{{ scope.row.adjusted_replenish_money }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="other_amounts"
                            v-if="versionMark == 2"
                        >
                            <template slot="header">
                                <span class="account">其他金额</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="account">{{ scope.row.other_amounts }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="receivable_amount"
                            v-if="versionMark == 2"
                        >
                            <template slot="header">
                                <span class="account">应收金额</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="account">{{ scope.row.receivable_amount }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="accumulated_amount"
                            v-if="versionMark == 2"
                            width="120"
                        >
                            <template slot="header">
                                <span class="total">累计金额</span>
                            </template>
                            <template slot-scope="scope">
                                <span class="total" :title="scope.row.accumulated_amount">{{ scope.row.accumulated_amount }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageData.currentPage"
                        :page-sizes="[10, 20, 30, 40, 50]"
                        :page-size="pageData.pagesize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageData.total"
                    >
                    </el-pagination>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            // 企业固定价数据
            gradeData: [],
            // 市场信息数据
            additionData: [],
            loading: false,
            // 根据编号获取档案信息
            contractInfo: {
                contract_price_versions: {},
            },
            // 获取收款记录
            proceedsDetail: {},
            // 获取收退款记录
            refundDetail: {},
            // 获取生产发货统计数据
            statisticsDetail: {},
            // 合同账务明细
            scheduleDetail: {},
            // 列表数据
            tableData: [],
            // 历史合同编号
            old_contract_number: '',
            // 合同编号
            contract_number: '',
            // 历史工程id
            old_engineering_id: '',
            // 工程id
            engineering_id: '',
            // 日期查询
            dateRange: [],
            // 分页数据
            pageData: {
                currentPage: 1,
                pagesize: 10,
                total: 0,
            },
            type: '1',
            // 累计应收
            ljysVal: '',
            // 累计实收
            ljssVal: '',
            // 逾期欠款
            yqqkVal: '',
            // 版本标识[1普通版本，2高级版]
            versionMark: 1,
        };
    },
    created() {
        this.loading = true;
        this.initTime();
        this.versionMark = this.$takeTokenParameters('AuthorizationVersion');
        // 历史合同接口
        if (this.extr.history) {
            const contract_number = this.extr.contract_number;
            this.contract_number = this.extr.contract_number;
            // 根据编号获取档案信息
            this.getDetailFun(`get_history_contract_basic_info/${contract_number}`, 'contractInfo');
            // 获取收款记录
            this.getDetailFun(`get_history_accounts_proceeds_list/${contract_number}`, 'proceedsDetail');
            // 获取收退款记录
            this.getDetailFun(`get_history_accounts_refund_list/${contract_number}`, 'refundDetail');
            // 获取生产发货统计数据
            this.getDetailFun(`get_history_delivery_statistics/${contract_number}`, 'statisticsDetail');
            // 获取合同进度数据
            this.getDetailFun(`get_history_contract_schedule?contract_number=${contract_number}`, 'scheduleDetail');
            // 获取任务信息列表数据
            this.getAccountList();
        } else {
            const contract_number = this.extr.contract_number;
            this.contract_number = this.extr.contract_number;
            // 根据编号获取档案信息
            this.getDetailFun(`get_contract_basic_info/${contract_number}`, 'contractInfo');
            // 获取收款记录
            this.getDetailFun(`get_accounts_proceeds_list/${contract_number}`, 'proceedsDetail');
            // 获取收退款记录
            this.getDetailFun(`get_accounts_refund_list/${contract_number}`, 'refundDetail');
            // 获取生产发货统计数据
            this.getDetailFun(`get_delivery_statistics/${contract_number}`, 'statisticsDetail');
            // 获取合同进度数据
            this.getDetailFun(`get_contract_schedule?contract_number=${contract_number}`, 'scheduleDetail');
            // 获取任务信息列表数据
            this.getAccountList();
        }
    },
    methods: {
        // 初始化时间范围为当前月
        initTime() {
            const endTime = util.currentTimeFormat('yyyy-MM-dd');
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const startTime = year + '-' + month + '-01';
            this.dateRange.push(startTime);
            this.dateRange.push(endTime);
        },
        getDetailFun(api, key) {
            this.$axios.get(`/interfaceApi/sale/contract_archives/${api}`).then(res => {
                if (res) {
                    this[key] = res;
                    if (key === 'contractInfo') {
                        if (this.extr.history) {
                            this.old_contract_number = '';
                            this.old_engineering_id = res.engineering_id;
                            this.engineering_id = '';
                        } else {
                            this.old_contract_number = res.old_contract_number ? res.old_contract_number : '';
                            this.engineering_id = res.engineering_id;
                            this.old_engineering_id = '';
                        }
                        // 固定价标号
                        const markArr = [];
                        // 市场价标号
                        const markArr1 = [];
                        // 固定价附加
                        const additArr = [];
                        // 市场价附加
                        const additArr1 = [];
                        if (res.contract_price_mark_num_list && res.contract_price_mark_num_list[0]) {
                            res.contract_price_mark_num_list.forEach(item => {
                                if (item.price_type === 1) {
                                    markArr.push(item);
                                } else {
                                    markArr1.push(item);
                                }
                            });
                        }
                        if (res.contract_price_additional_list && res.contract_price_additional_list[0]) {
                            res.contract_price_additional_list.forEach(item => {
                                if (item.price_type === 1) {
                                    additArr.push(item);
                                } else {
                                    additArr1.push(item);
                                }
                            });
                        }
                        this.gradeData.push({
                            title: '标号',
                            data: markArr,
                        });
                        this.gradeData.push({
                            title: '附加标号',
                            data: additArr,
                        });
                        this.additionData.push({
                            title: '标号',
                            data: markArr1,
                        });
                        this.additionData.push({
                            title: '附加标号',
                            data: additArr1,
                        });
                    }
                }
            }).catch(error => {
                this.$message.error(error);
            });
        },
        // 点合同编号，打开流程办理
        openProcessManagement() {
            // this.$WFShowSceneLayer(this.business_instance_id, 'view');
        },
        // 查看历史合同档案信息
        openHistoricalContract() {
            this.$toast({
                title: true,
                text: '历史合同档案信息',
                type: 'eject',
                width: '78%',
                height: '93%',
                t_url: 'salesBusiness/contractRecord/index',
                viewPosition: 'view',
                extr: {
                    contract_number: this.old_contract_number,
                    history: true,
                },
            });
        },
        // 获取分页任务信息
        async getAccountList() {
            const res = await this.getAccountListApi();
            if (res) {
                this.tableData = res.list.rows;
                this.pageData.total = res.list.total;
                this.ljysVal = res.receivable_amount_total;
                this.ljssVal = res.accounts_receivable_balance;
                this.yqqkVal = res.supplement_accounts_receivable;
                // this.receivable_amount_total = res.receivable_amount_total;
                // this.accounts_receivable_balance = res.accounts_receivable_balance;
                // this.supplement_accounts_receivable = res.supplement_accounts_receivable;
            }
            this.loading = false;
        },
        // 分页拉取任务信息
        getAccountListApi() {
            let queryStr = '?contract_number=' + this.contract_number + '&pageindex='
            + this.pageData.currentPage + '&pagesize=' + this.pageData.pagesize;
            if (this.dateRange && this.dateRange[0]) {
                queryStr = queryStr + '&begin_time=' + this.dateRange[0] + '&end_time=' + this.dateRange[1];
            }
            const path = '/interfaceApi/sale/contract_archives/get_accounts_dailytask_list' + queryStr;
            return this.$axios.$get(path, { defEx: true });
        },
        // 按时间范围拉取
        selectList() {
            this.currentPage = 1;
            this.getAccountList();
        },
        // 收款记录class
        payClass(index) {
            const str = 'item-bg' + index % 2;
            return str;
        },
        // 改变每页条数
        handleSizeChange(size) {
            this.pageData.pagesize = size;
            this.getAccountList();
        },
        // 切换页码
        handleCurrentChange(cur) {
            this.pageData.currentPage = cur;
            this.getAccountList();
        },
        // 导出
        exportData() {
            const contract_number = this.extr.history ? this.old_contract_number : this.contract_number;
            let url = '/interfaceApi/sale/contract_archives/export_daily_task?contract_number=' + contract_number;
            if (this.dateRange && this.dateRange[0]) {
                url = url + '&begin_time=' + this.dateRange[0] + '&end_time=' + this.dateRange[1];
            }
            this.$axios.get(url, { responseType: 'arraybuffer' })
                .then(res => {
                    const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
                    const fileName = '导出数据.xls';
                    if ('download' in document.createElement('a')) { // 非IE下载
                        const elink = document.createElement('a');
                        elink.download = fileName;
                        elink.style.display = 'none';
                        elink.href = URL.createObjectURL(blob);
                        document.body.appendChild(elink);
                        elink.click();
                        URL.revokeObjectURL(elink.href); // 释放URL 对象
                        document.body.removeChild(elink);
                    } else { // IE10+下载
                        navigator.msSaveBlob(blob, fileName);
                    }
                }).catch(() => {
                    this.$message.error('系统异常');
                });
        },
        // 打开施工进度弹窗
        openPopSgjdFun() {
            const engineering_id = this.extr.history ? this.old_engineering_id : this.engineering_id;
            this.$toast({
                title: true,
                text: '工程进度',
                type: 'eject', width: '80%',
                height: '90%',
                t_url: 'salesBusiness/projectInfoManagement/projectInfo/projectProgress',
                viewPosition: 'view',
                extr: {
                    closeBtn: {},
                    engineering_id,
                },
            });
        },
        // 打开调差弹窗
        openPopTcFun() {
            const contract_number = this.extr.history ? this.old_contract_number : this.contract_number;
            this.$toast({
                title: true,
                type: 'eject',
                width: '13.4rpm',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'contractManage_schedule_adjustment?contract_number=' + contract_number,
                        QueryString: '',
                    },
                },
            });
        },
        // 打开管控数据弹窗
        openPopGksjFun() {
            const contract_number = this.extr.history ? this.old_contract_number : this.contract_number;
            this.$toast({
                title: true,
                text: '管控数据详情',
                type: 'eject',
                width: '14rem',
                height: 'auto',
                t_url: 'contractControl/orderControlDetails',
                extr: {
                    control_instance_code: contract_number,
                    type: 2,
                },
            });
        },
        // 打开弹窗
        openPopFun() {
            const price_type = this.contractInfo.price_type;
            const contract_number = this.extr.history ? this.old_contract_number : this.contract_number;
            let tableCode = '';
            if (price_type === 1) {
                tableCode = 'contract_price_record';
            } else if (price_type === 2) {
                tableCode = 'contract_price_record_xxj';
            }
            this.$toast(
                {
                    title: true,
                    type: 'eject',
                    width: '13.4rpm',
                    t_url: 'tableList/index',
                    extr: {
                        code: {
                            TableCode: tableCode,
                            QueryString: 'contract_number=' + contract_number,
                        },
                    },
                }
            );
        },
    },
};
</script>
<style lang="stylus" scoped>
/deep/ .table-bg .cell
    padding 0 !important
.b_color_s_lan
    background #394df0
.c_color_s_lan
    color #394df0
.b_color_lan
    background #69a9ee
.c_color_lan
    color #69a9ee
.b_color_qing
    background #45c6ed
.c_color_qing
    color #45c6ed
.b_color_cheng
    background #fff8f3 !important
.b_color_s_cheng
    background #45c6ed !important
.c_color_cheng
    color #fd8c1c
.b_color_red
    background #fef5f5 !important
.c_color_red
    color #f43939

.contractArchives
    height 100%
    padding .1rem
    font-size .16rem
    overflow-y scroll
    header
        border 1px solid #D7D7D7
        background-image none
        height 1rem
        display flex
        .item
            flex 1
            display flex
            line-height .45rem
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            padding 0 .1rem
            .tip_span
                font-size .2rem
            .circle
                width .6rem
                height .6rem
                display inline-block
                text-align center
                line-height .6rem
                color #fff
                background #5588F1
                border-radius 50%
                margin 0.2rem 0.2rem 0 .05rem
                .iconfont
                    font-size .4rem
            .contractNo
                font-size .2rem
            .el-button
                margin-left 0.3rem
                padding 0
                font-size 0.16rem
                line-height 0.31rem
                padding 0 0.15rem
                vertical-align top
                margin-top 0.05rem
        .item-border
            position relative
            &:after
                content ''
                position absolute
                right 0
                height .16rem
                top .15rem
                border-right 1px solid #D7D7D7
            .item_title
                text-align: left !important;
    .main_box
        height calc(100% - 1.3rem)
        .payment_bbai
            background #fff !important
        .payment_t
            height .4rem !important
        .payment
            display flex
            height .6rem
            border 1px solid #8fd9ff
            margin-top .2rem
            background #f1f9ff
            .payment_title_t
                line-height .4rem !important
            .payment_title
                padding 0 .2rem
                background #5588F1
                font-size .15rem
                text-align center
                line-height .6rem
                color #fff
                border-radius: 0 50px 50px 0;
            .payment_title_bc
                background #f86c39 !important
            .payment_title_bl
                background #3f54f9 !important
            .payment_txt_t
                line-height .4rem
            .payment_txt_f
                padding-top 4px
            .payment_txt
                white-space nowrap
                text-overflow:ellipsis
                overflow hidden
                word-break break-all
                color #568cc6
                background #ffffff !important
                display flex
                margin 0 0 0 .2rem
                text-align center
                .hexagon_bno
                    width 25px
                    height 100%
                    background #fff
                    display inline-block
                    border-radius: 0 50px 50px 0;
                    margin-right .2rem
                .hexagon_box
                    border-radius: 0 50px 50px 0;
                    display flex
                    padding 0 .2rem 0 0
                .hexagon_bred
                    background #fdf3ef
                    margin 0 .2rem 0 0
                .hexagon_blan
                    border-radius: 0 50px 50px 0;
                    background #efeffc
                    display flex
                    padding-right .2rem
    .pricing_box
        display flex
        .pricing1
            margin-right 12px
        .pricing
            width 50%
            border 1px solid #69a9ee
            display flex
            background #f1f9ff
            .pricing_l
                width 21px
                padding 15px 2px
                color #fff
            .title_box
                margin 9px 0
                .title_span_btn
                    margin 0 8px
                .title_span
                    border-radius 30%
                    display inline-block
                    padding 0 6px
                    text-align center
                    margin 0 8px
                    color #fff
                .title_sp
                    margin 0 8px
    .payment_goods
        border 1px solid #dcdcdc
        margin-top .2rem
        .payment_title_bzb
            height .6rem
            padding .1rem 0 0 .2rem
        .payment_top
            display flex
            .payment_title
                padding 0 .08rem
                background #0db99f
                font-size .15rem
                width 140px
                color #fff
                display flex
                z-index 3
                align-items center
                justify-content center
                border-radius: 0 50px 50px 0;
            .payment_inner
                display flex
                flex 1
                line-height .3rem
                .payment_inner_chunk
                    width 25%
                    text-align center
                    display inline-block
                    position relative
                    z-index 0
                    padding 0 .05rem
                    font-size .08rem
                    span
                        color #8f8f8f
                .payment_inner_chunk::before
                    content ''
                    position absolute
                    left 0
                    top 0
                    right 0
                    bottom 0
                    border 1px solid #ddd
                    background-color #fff
                    transform skewX(-10deg)
                    z-index -1
                .payment_inner_b::before
                    background #f8f8f8
        .payment_bottom
            display flex
            margin .1rem 0
            .payment_bottom_chunk
                width 14%
                height .4rem
                margin 0 12px
                background #f2fbfc
                line-height .4rem
                span
                    color #0db99f
    .money_box
        border 1px solid #dcdcdc
        margin-top .2rem
        .money_top
            display flex
            .money_title
                padding 0 .1rem
                background #0db99f
                font-size .2rem
                width 200px
                color #fff
                display flex
                z-index 3
                align-items center
                justify-content center
                border-radius: 0 50px 50px 0;
            .money_title_bq
                background #01a4ce !important
        .money_center
            display flex
            margin .1rem 0
            position relative
            .money_center_chunk
                font-size .08rem
                width 9%
                height .6rem
                margin 0 .03rem
                background #f2fbfc
                padding .08rem
            .right_btn
                position absolute
                bottom .05rem
                right .15rem
    .table_box_bzb
        margin-top .2rem !important
        height calc(100% - 1.7rem)
    .table_box
        .date-picker
            margin .1rem 0
            .export_btn
                float right
                color #fff
                border-radius .05rem
                margin-top .05rem
                font-size .14rem
        .table_bg_bzb
            height calc(100% - 1rem) !important
            .el-table
                height calc(100% - .2rem) !important
        .table-bg
            height calc(100% - 5.3rem)
            .el-table
                height calc(100% - .4rem)
                .green
                    color #00839B
                .green_pro
                    color #4dc325
                .blue
                    color #014ECA
                .b_green
                    background #e9f6f8
                .b_green_pro
                    background #f1f9ee
                .b_blue
                    background #f0f5fe
                .purple
                    color #772CC3
                .account
                    color #022782
                .total
                    color #C9661E
                .b_total
                    background #f7efe6
            .el-pagination
                text-align right
                margin-top .08rem
</style>