import jwtDecode from 'jwt-decode';
const Utils = Object.create(null);
Utils.getGuid = function () {
    function S4() {
        return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
    }
    return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
};
Utils.jwtToken = function (name) {
    const token = window.sessionStorage.getItem('token');
    // 解析token，获取token中数据
    const decode = jwtDecode(token);
    const str = decode[name];
    return str;
};
/*
** 比较两个json是否有差异
*/
function isObj(object) {
    return object && typeof object === 'object' && Object.prototype.toString.call(object).toLowerCase() === '[object object]';
}

function isArray(object) {
    return object && typeof object === 'object' && object.constructor === Array;
}

function getLength(object) {
    let count = 0;
    for (const i in object) {count++;}
    return count;
}
function CompareObj(objA, objB, flag) {
    for (const key in objA) {
        if (!flag) {break;}
        if (!objB.hasOwnProperty(key)) {
            flag = false;
            break;
        }
        if (!isArray(objA[key])) { // 子级不是数组时,比较属性值
            if (objB[key] !== objA[key]) {
                flag = false;
                break;
            }
        } else {
            if (!isArray(objB[key])) {
                flag = false;
                break;
            }
            const oA = objA[key];
            const oB = objB[key];
            if (oA.length !== oB.length) {
                flag = false;
                break;
            }
            for (const k in oA) {
                if (!flag) {break;}
                flag = CompareObj(oA[k], oB[k], flag);
            }
        }
    }
    return flag;
}
Utils.Compare = function (objA, objB) {
    if (!isObj(objA) || !isObj(objB)) {return false;} // 判断类型是否正确
    if (getLength(objA) !== getLength(objB)) {return false;} // 判断长度是否一致
    return CompareObj(objA, objB, true); // 默认为true
};
// 保留六位小数
Utils.toFixed6 = function (num) {
    const newNum = (Math.round(num * 1000000) / 1000000).toFixed(6);
    return Number(newNum);
};
// 集合数据中的null值转换成0
Utils.nullToZero = function (data) {
    if (Array.isArray(data)) {
        // console.log(data);
    } else {
        for (const key in data) {
            if (!data[key]) {
                data[key] = 0;
            } else {
                data[key] = parseFloat(data[key]);
            }
        }
    }
    return data;
};
Utils.setCookie = function (name, value, iDay) {
    const oDate = new Date();
    oDate.setDate(oDate.getDate() + iDay);
    document.cookie = name + '=' + value + ';expires=' + oDate;
};
Utils.getCookie = function (name) {
    const arr = document.cookie.split('; ');
    for (let i = 0; i < arr.length; i++) {
        const arr2 = arr[i].split('=');
        if (arr2[0] === name) {
            return arr2[1];
        }
    }
    return '';
};
Utils.removeCookie = function (name) {
    Utils.setCookie(name, 1, -1);
};
/*
** 格式化当前时间
var date1 = currentTimeFormat("yyyy-MM-dd");
var date2 = currentTimeFormat("yyyy-MM-dd HH:mm:ss");
*/
Utils.currentTimeFormat = fmt => {
    return Utils.timeFormat(new Date(), fmt);
};
/**
 * 格式化时间
 * @param date  日期对象
 * @param fmt   格式化格式
 * @returns {*} 时间字符串 yyyy-MM-dd HH:mm:ss
 */
Utils.timeFormat = (date, fmt) => {
    const o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'H+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds(), // 毫秒
    };
    if (/(y+)/.test(fmt)) {fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));}
    for (const k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
        }
    }
    return fmt;
};
/**
 * 图片格式处理
 */
Utils.setImgFun = url => {
    const imgDatas = [
        '.png',
        '.jpg',
        '.bmp',
        '.jpeg',
        '.png',
        '.jif',
    ];
    let newUrl = '';
    imgDatas.forEach(item => {
        if (url.indexOf(item) !== -1) {
            newUrl = url.split(item)[0] + item;
        }
    });
    return newUrl;
};

export default Utils;
